<template>
  <div>
    <a-menu v-model="current" mode="horizontal">
      <a-menu-item key="personalData">Data Pribadi</a-menu-item>
      <a-menu-item key="parentData">Data Orang Tua/Wali</a-menu-item>
      <a-menu-item key="detailData">Data Rincian</a-menu-item>
      <a-menu-item key="registrationData">Data Registrasi</a-menu-item>
    </a-menu>
    <template v-if="current[0] === 'personalData'">
      <header-menu class="mt-4" title="profil" />
      <a-descriptions bordered :column="1">
        <a-descriptions-item label="Nama"
          ><a-input
            v-if="isEditable"
            :value="data.nama"
            @change="(e) => handleChange(e.target.value, 'nama')"
            class="w-100"
            size="large"
          />
          <template v-else>
            {{ data.nama ? data.nama : "-" }}
          </template>
        </a-descriptions-item>
        <a-descriptions-item label="Jenis Kelamin">
          <a-radio-group
            class="w-100"
            size="large"
            default-value="Laki-Laki"
            button-style="solid"
            v-if="isEditable"
            :value="data.jenis_kelamin"
            @change="(e) => handleChange(e.target.value, 'jenis_kelamin')"
          >
            <a-radio-button class="w-50 text-center" value="Laki-Laki">
              Laki-Laki
            </a-radio-button>
            <a-radio-button class="w-50 text-center" value="Perempuan">
              Perempuan
            </a-radio-button>
          </a-radio-group>
          <template v-else>
            {{ data.jenis_kelamin ? data.jenis_kelamin : "-" }}
          </template>
        </a-descriptions-item>
        <a-descriptions-item label="NISN">
          <a-input
            v-if="isEditable"
            :value="data.nisn"
            @change="(e) => handleChange(e.target.value, 'nisn')"
            class="w-100"
            size="large"
          />
          <template v-else>
            {{ data.nisn ? data.nisn : "-" }}
          </template>
        </a-descriptions-item>
        <a-descriptions-item label="NIK/No. Kitas (WNA)">
          <a-input
            v-if="isEditable"
            :value="data.nik_kitas"
            @change="(e) => handleChange(e.target.value, 'nik_kitas')"
            class="w-100"
            size="large"
          />
          <template v-else>
            {{ data.nik_kitas ? data.nik_kitas : "-" }}
          </template>
        </a-descriptions-item>
        <a-descriptions-item label="Scan Kartu Keluarga"
          >
          <a v-if="files.kartu_keluarga" target="_blank" :href="`${config.apiUrl}/${files.kartu_keluarga.filepath}${files.kartu_keluarga.filename}`">{{files.kartu_keluarga.filename}}</a>
          <div v-else>-</div>
        </a-descriptions-item>
        <a-descriptions-item label="Tempat dan Tanggal Lahir">
          <a-input
            v-if="isEditable"
            :value="
              data.tempat_tanggal_lahir
                ? data.tempat_tanggal_lahir.tempat
                : null
            "
            @change="
              (e) =>
                handleChange(e.target.value, 'tempat_tanggal_lahir', 'tempat')
            "
            class="w-50"
            size="large"
          />
          <a-date-picker
            v-if="isEditable"
            :value="
              data.tempat_tanggal_lahir
                ? moment(
                    data.tempat_tanggal_lahir.tanggal,
                    'DD MMMM YYYY'
                  ).locale('id')
                : null
            "
            format="DD MMMM YYYY"
            @change="
              (e, string) =>
                handleChange(string, 'tempat_tanggal_lahir', 'tanggal')
            "
            class="w-50"
            size="large"
          />
          <template v-else>
            {{
              data.tempat_tanggal_lahir
                ? `${data.tempat_tanggal_lahir.tempat}, ${parseMoment(
                    data.tempat_tanggal_lahir.tanggal,
                    "DD MMMM YYYY"
                  )}`
                : "-"
            }}
          </template>
        </a-descriptions-item>
        <a-descriptions-item label="No. Registrasi Akta Lahir">
          <a-input
            v-if="isEditable"
            :value="data.akta_lahir"
            @change="(e) => handleChange(e.target.value, 'akta_lahir')"
            class="w-100"
            size="large"
          />
          <template v-else>
            {{ data.akta_lahir ? data.akta_lahir : "-" }}
          </template>
        </a-descriptions-item>
        <a-descriptions-item label="Scan Akta Kelahiran"
          >
          <a v-if="files.akta_lahir" target="_blank" :href="`${config.apiUrl}/${files.akta_lahir.filepath}${files.akta_lahir.filename}`">{{files.akta_lahir.filename}}</a>
          <div v-else>-</div>
        </a-descriptions-item>
        <a-descriptions-item label="Anak Ke">
          <a-input-number
            v-if="isEditable"
            :value="data.anak_ke"
            @change="(e) => handleChange(e, 'anak_ke')"
            size="large"
          />
          <template v-else>
            {{ data.anak_ke ? data.anak_ke : "-" }}
          </template>
        </a-descriptions-item>
        <a-descriptions-item label="Agama & Kepercayaan">
          <a-select
            size="large"
            class="w-50"
            v-if="isEditable"
            :value="data.agama"
            @change="(e) => handleChange(e, 'agama')"
          >
            <a-select-option
              v-for="item in religions"
              :key="item.key"
              :value="item.title"
            >
              {{ item.title }}
            </a-select-option>
          </a-select>
          <template v-else>
            {{ data.agama ? data.agama : "-" }}
          </template>
        </a-descriptions-item>
        <a-descriptions-item label="Kewarganegaraan">
          <a-select
            size="large"
            v-if="isEditable"
            :value="data.kewarganegaraan"
            @change="(e) => handleChange(e, 'kewarganegaraan')"
          >
            <a-select-option
              v-for="item in ['WNI', 'WNA']"
              :key="item"
              :value="item"
            >
              {{ item }}
            </a-select-option>
          </a-select>
          <template v-else>
            {{ data.kewarganegaraan ? data.kewarganegaraan : "-" }}
          </template>
        </a-descriptions-item>
        <a-descriptions-item label="Berkebutuhan Khusus">
          {{ data.berkebutuhan_khusus ? listBerkebutuhanKusus : "Tidak Ada" }}
        </a-descriptions-item>
      </a-descriptions>
      <header-menu class="mt-4" title="alamat saat ini" />
      <a-descriptions bordered :column="1">
        <a-descriptions-item label="Alamat Lengkap"
          >{{
            data.alamat_lengkap
              ? `${data.alamat_lengkap.jalan} RT ${data.alamat_lengkap.rt} RW ${data.alamat_lengkap.rw}, ${data.alamat_lengkap.kelurahan_desa}, ${data.alamat_lengkap.kecamatan}, ${data.alamat_lengkap.kota}, ${data.alamat_lengkap.provinsi}, ${data.alamat_lengkap.kode_pos}`
              : "-"
          }}
        </a-descriptions-item>
        <a-descriptions-item label="Tempat Tinggal">
          <a-select
            size="large"
            class="w-50"
            v-if="isEditable"
            :value="data.tempat_tinggal ? data.tempat_tinggal.deskripsi : null"
            @change="(e) => handleChange(e, 'tempat_tinggal', 'deskripsi')"
          >
            <a-select-option
              v-for="item in livedWithList"
              :key="item.key"
              :value="item.title"
            >
              {{ item.title }}
            </a-select-option>
          </a-select>
          <template v-else>
            {{ data.tempat_tinggal ? data.tempat_tinggal.deskripsi : "-" }}
          </template>
        </a-descriptions-item>
        <a-descriptions-item label="Moda Transportasi">
          <a-select
            size="large"
            class="w-50"
            v-if="isEditable"
            :value="
              data.moda_transportasi ? data.moda_transportasi.deskripsi : null
            "
            @change="(e) => handleChange(e, 'moda_transportasi', 'deskripsi')"
          >
            <a-select-option
              v-for="item in transportationModeList"
              :key="item.key"
              :value="item.title"
            >
              {{ item.title }}
            </a-select-option>
          </a-select>
          <template v-else>
            {{
              data.moda_transportasi ? data.moda_transportasi.deskripsi : "-"
            }}
          </template>
        </a-descriptions-item>
      </a-descriptions>
      <header-menu class="mt-4" title="lainnya" />
      <a-descriptions bordered :column="1">
        <a-descriptions-item label="Nomor KKS">
          <a-input
            v-if="isEditable"
            :value="data.nomor_kks"
            @change="(e) => handleChange(e.target.value, 'nomor_kks')"
            class="w-100"
            size="large"
          />
          <template v-else>
            {{ data.nomor_kks ? data.nomor_kks : "-" }}
          </template>
        </a-descriptions-item>
        <a-descriptions-item label="Penerima KPS/PKH">
          {{ data.kps_pkh ? "Iya" : "Tidak" }}
        </a-descriptions-item>
        <a-descriptions-item v-if="data.kps_pkh" label="No. KPS/PKH">
          {{ data.kps_pkh }}
        </a-descriptions-item>
        <a-descriptions-item label="Penerima KIP">
          {{ data.kip ? "Iya" : "Tidak" }}
        </a-descriptions-item>
        <a-descriptions-item v-if="data.kip" label="Terima Fisik Kartu (KIP)">
          {{ data.kip.isTerimaFisikKIP ? "Iya" : "Tidak" }}
        </a-descriptions-item>
        <a-descriptions-item v-if="data.kip" label="Terima Fisik Kartu (KIP)">
          {{ data.kip.isTerimaFisikKIP ? "Iya" : "Tidak" }}
        </a-descriptions-item>
        <a-descriptions-item v-if="data.kip" label="No. KIP">
          {{ data.kip.nomor }}
        </a-descriptions-item>
        <a-descriptions-item v-if="data.kip" label="Nama Tertera di KIP">
          {{ data.kip.nama }}
        </a-descriptions-item>
        <a-descriptions-item
          v-if="!data.kip"
          label="Usulan Dari Sekolah (Layak PIP)"
        >
          {{ data.pip ? "Iya" : "Tidak" }}
        </a-descriptions-item>
        <a-descriptions-item
          v-if="!data.kip && data.pip"
          label="Alasan Layak Layak PIP"
        >
          {{ data.pip.alasan }}
        </a-descriptions-item>
      </a-descriptions>
    </template>
    <template v-if="current[0] === 'parentData'">
      <header-menu class="mt-4" title="data orangtua/wali" />
      <a-table
        :columns="columns"
        :data-source="dataTable"
        :scroll="{ x: 1300 }"
        :pagination="{ pageSize: 30, hideOnSinglePage: true }"
        bordered
      >
        <div slot="hubungan" slot-scope="value">
          {{
            showHubungan(value) === "wali"
              ? "Wali (opsional)"
              : showHubungan(value)
          }}
        </div>
        <span slot="nullStr" slot-scope="text">
          <span v-if="!text">-</span>
          <span v-else>{{ text }}</span>
        </span>
        <span slot="pendidikan" slot-scope="text">
          <span v-if="!text">-</span>
          <span v-else>{{
            setValueTable(text.deskripsi, "educationsList")
          }}</span>
        </span>
        <span slot="penghasilan" slot-scope="text">
          <span v-if="!text">-</span>
          <span v-else>{{
            setValueTable(text.deskripsi, "monthlyRevenueList")
          }}</span>
        </span>
        <span slot="berkebutuhan_khusus" slot-scope="value, record">
          <span v-if="!record.berkebutuhan_khusus">-</span>
          <template v-else>
            <a-tag v-if="!record.berkebutuhan_khusus.length" color="green"
              >tidak</a-tag
            >
            <template v-else>
              <a-tag
                class="m-1"
                v-for="(kebutuhan, idx) in record.berkebutuhan_khusus"
                :key="idx"
                color="orange"
                >{{ kebutuhan.deskripsi }}</a-tag
              >
            </template>
          </template>
        </span>
        <!-- <div slot="actions" slot-scope="value, record">
          <a-button
            :type="record.nama ? 'default' : 'primary'"
            class="mr-3"
            :class="
              record.nama ? ['text-warning', 'border', 'border-warning'] : []
            "
            size="large"
            @click.prevent="showModal(record)"
          >
            <a-icon :type="record.nama ? 'edit' : 'plus'" />{{
              record.nama ? "Ubah" : "Masukkan"
            }}
          </a-button>
        </div> -->
      </a-table>
      <header-menu class="mt-4" title="kontak" />
      <a-descriptions bordered :column="1">
        <a-descriptions-item label="Nomor Telepon Rumah">
          <a-input
            v-if="isEditable"
            :value="data.nomor_telepon"
            @change="(e) => handleChange(e.target.value, 'nomor_telepon')"
            class="w-100"
            size="large"
          />
          <template v-else>
            {{ data.nomor_telepon ? data.nomor_telepon : "-" }}
          </template>
        </a-descriptions-item>
        <a-descriptions-item label="Nomor HP">
          <a-input
            v-if="isEditable"
            :value="data.nomor_hp"
            @change="(e) => handleChange(e.target.value, 'nomor_hp')"
            class="w-100"
            size="large"
          />
          <template v-else>
            {{ data.nomor_hp ? data.nomor_hp : "-" }}
          </template>
        </a-descriptions-item>
        <a-descriptions-item label="Email">
          <a-auto-complete
            :data-source="dataSource"
            size="large"
            class="w-100"
            v-if="isEditable"
            :value="data.email"
            @change="(e) => handleChange(e, 'email')"
          />
          <template v-else>
            {{ data.email ? data.email : "-" }}
          </template>
        </a-descriptions-item>
        <a-descriptions-item label="Email Perwakilan Orang Tua">
          <a-auto-complete
            :data-source="dataSource"
            size="large"
            class="w-100"
            v-if="isEditable"
            :value="data.email_perwakilan_orangtua"
            @change="(e) => handleChange(e, 'email_perwakilan_orangtua')"
          />
          <template v-else>
            {{
              data.email_perwakilan_orangtua
                ? data.email_perwakilan_orangtua
                : "-"
            }}
          </template>
        </a-descriptions-item>
        <a-descriptions-item label="Jenis Ekstrakurikuler">
          {{ data.jenis_ekstrakurikuler ? listEkstrakurikuler : "Tidak Ada" }}
        </a-descriptions-item>
      </a-descriptions>
    </template>
    <template v-if="current[0] === 'detailData'">
      <header-menu class="mt-4" title="data periodik" />
      <a-descriptions bordered :column="1">
        <a-descriptions-item label="Tinggi Badan (cm)">
          <a-input-number
            v-if="isEditable"
            :value="data.tinggi_badan"
            @change="(e) => handleChange(e, 'tinggi_badan')"
            size="large"
          />
          <template v-else>
            {{ data.tinggi_badan ? data.tinggi_badan : "-" }}
          </template>
        </a-descriptions-item>
        <a-descriptions-item label="Berat Badan (kg)"
          ><a-input-number
            v-if="isEditable"
            :value="data.berat_badan"
            @change="(e) => handleChange(e, 'berat_badan')"
            size="large"
          />
          <template v-else>
            {{ data.berat_badan ? data.berat_badan : "-" }}
          </template>
        </a-descriptions-item>
        <a-descriptions-item label="Lingkar Kepala (cm)"
          ><a-input-number
            v-if="isEditable"
            :value="data.lingkar_kepala"
            @change="(e) => handleChange(e, 'lingkar_kepala')"
            size="large"
          />
          <template v-else>
            {{ data.lingkar_kepala ? data.lingkar_kepala : "-" }}
          </template>
        </a-descriptions-item>
        <a-descriptions-item label="Jarak Tempat Tinggal ke Sekolah"
          >{{
            data.geolokasi_tempat_tinggal
              ? data.geolokasi_tempat_tinggal.jarak + "KM"
              : "-"
          }}
        </a-descriptions-item>
        <a-descriptions-item label="Waktu Tempuh ke Sekolah">
          {{
            data.waktu_tempuh_ke_sekolah
              ? getDuration(data.waktu_tempuh_ke_sekolah)
              : "-"
          }}
        </a-descriptions-item>
        <a-descriptions-item label="Jumlah Saudara Kandung">
          <a-input-number
            v-if="isEditable"
            :value="data.jumlah_saudara_kandung"
            @change="(e) => handleChange(e, 'jumlah_saudara_kandung')"
            size="large"
          />
          <template v-else>
            {{
              data.jumlah_saudara_kandung ? data.jumlah_saudara_kandung : "-"
            }}
          </template>
        </a-descriptions-item>
      </a-descriptions>
      <header-menu class="mt-4" title="prestasi" />
      <table-prestasi :dataTable="listPrestasi" isAdmin />
      <header-menu class="mt-4" title="Beasiswa" />
      <table-beasiswa :dataTable="listBeasiswa" isAdmin />
    </template>
    <template v-if="current[0] === 'registrationData'">
      <a-descriptions class="mt-4" bordered :column="1">
        <a-descriptions-item label="Jenis Pendaftaran">
          <a-select
            size="large"
            class="w-100"
            v-if="isEditable"
            :value="
              data.jenis_pendaftaran ? data.jenis_pendaftaran.deskripsi : null
            "
            @change="(e) => handleChange(e, 'jenis_pendaftaran', 'deskripsi')"
          >
            <a-select-option
              v-for="item in jenisPendaftaranList"
              :key="item.key"
              :value="item.title"
            >
              {{ item.title }}
            </a-select-option>
          </a-select>
          <template v-else>
            {{
              data.jenis_pendaftaran ? data.jenis_pendaftaran.deskripsi : "-"
            }}
          </template>
        </a-descriptions-item>
        <a-descriptions-item label="NIS"
          ><a-input
            v-if="isEditable"
            :value="data.nis"
            @change="(e) => handleChange(e.target.value, 'nis')"
            class="w-100"
            size="large"
          />
          <template v-else>
            {{ data.nis ? data.nis : "-" }}
          </template>
        </a-descriptions-item>
        <a-descriptions-item label="Tanggal Masuk Sekolah">
          <a-date-picker
            v-if="isEditable"
            :value="
              moment(data.tanggal_masuk_sekolah, 'DD MMMM YYYY').locale('id')
            "
            format="DD MMMM YYYY"
            @change="
              (e, string) => handleChange(string, 'tanggal_masuk_sekolah')
            "
            class="w-50"
            size="large"
          />
          <template v-else>
            {{
              data.tanggal_masuk_sekolah
                ? parseMoment(data.tanggal_masuk_sekolah, "DD MMMM YYYY")
                : "-"
            }}
          </template>
        </a-descriptions-item>
        <a-descriptions-item label="Provinsi Asal Sekolah"
          >{{ data.asal_sekolah ? data.asal_sekolah.provinsi : "-" }}
        </a-descriptions-item>
        <a-descriptions-item label="Kota Asal Sekolah"
          >{{ data.asal_sekolah ? data.asal_sekolah.kota : "-" }}
        </a-descriptions-item>
        <a-descriptions-item label="Jenjang Asal Sekolah">
          <a-select
            size="large"
            class="w-50"
            v-if="isEditable"
            :value="data.asal_sekolah.jenjang"
            @change="(e) => handleChange(e, 'asal_sekolah', 'jenjang')"
          >
            <a-select-option
              v-for="item in jenjangSekolahList"
              :key="item.key"
              :value="item.title"
            >
              {{ item.title }}
            </a-select-option>
          </a-select>
          <template v-else>
            {{ data.asal_sekolah ? data.asal_sekolah.jenjang : "-" }}
          </template>
        </a-descriptions-item>
        <a-descriptions-item label="Status Asal Sekolah">
          <a-select
            size="large"
            class="w-25"
            v-if="isEditable"
            :value="data.asal_sekolah.status"
            @change="(e) => handleChange(e, 'asal_sekolah', 'status')"
          >
            <a-select-option
              v-for="item in statusSekolahList"
              :key="item.key"
              :value="item.title"
            >
              {{ item.title }}
            </a-select-option>
          </a-select>
          <template v-else>
            {{ data.asal_sekolah ? data.asal_sekolah.status : "-" }}
          </template>
        </a-descriptions-item>
        <a-descriptions-item label="Nama Asal Sekolah">
          <a-input
            v-if="isEditable"
            :value="data.asal_sekolah.nama"
            @change="
              (e) => handleChange(e.target.value, 'asal_sekolah', 'nama')
            "
            class="w-100"
            size="large"
          />
          <template v-else>
            {{ data.asal_sekolah ? data.asal_sekolah.nama : "-" }}
          </template>
        </a-descriptions-item>
        <a-descriptions-item label="Nomor Peserta Ujian"
          ><a-input
            v-if="isEditable"
            :value="data.nomor_peserta_ujian"
            @change="(e) => handleChange(e.target.value, 'nomor_peserta_ujian')"
            class="w-100"
            size="large"
          />
          <template v-else>
            {{ data.nomor_peserta_ujian ? data.nomor_peserta_ujian : "-" }}
          </template>
        </a-descriptions-item>
        <a-descriptions-item label="Nomor Seri Ijazah"
          ><a-input
            v-if="isEditable"
            :value="data.nomor_seri_ijazah"
            @change="(e) => handleChange(e.target.value, 'nomor_seri_ijazah')"
            class="w-100"
            size="large"
          />
          <template v-else>
            {{ data.nomor_seri_ijazah ? data.nomor_seri_ijazah : "-" }}
          </template>
        </a-descriptions-item>
        <a-descriptions-item label="Scan Ijazah Sekolah Jenjang Sebelumnya"
          >
          <a v-if="files.ijazah" target="_blank" :href="`${config.apiUrl}/${files.ijazah.filepath}${files.ijazah.filename}`">{{files.ijazah.filename}}</a>
          <div v-else>-</div>
        </a-descriptions-item>
        <a-descriptions-item label="Nomor Seri SKHUS"
          ><a-input
            v-if="isEditable"
            :value="data.nomor_seri_skhus"
            @change="(e) => handleChange(e.target.value, 'nomor_seri_skhus')"
            class="w-100"
            size="large"
          />
          <template v-else>
            {{ data.nomor_seri_skhus ? data.nomor_seri_skhus : "-" }}
          </template>
        </a-descriptions-item>
        <a-descriptions-item label="Scan Rapor Sekolah Jenjang Sebelumnya"
          >
          <a v-if="files.rapor" target="_blank" :href="`${config.apiUrl}/${files.rapor.filepath}${files.rapor.filename}`">{{files.rapor.filename}}</a>
          <div v-else>-</div>
        </a-descriptions-item>
        <!-- <a-descriptions-item label="Dokumen Terlampir"
          >
          <template v-if="data.files && data.files.length">
            <a v-for="dokumen in data.files" :key="dokumen.id" src="#">{{dokumen.filename}}</a>
          </template>
          <div v-else>-</div>
        </a-descriptions-item> -->
      </a-descriptions>
      <header-menu class="mt-4" title="Pendaftaran Keluar" />
      <a-descriptions bordered :column="1">
        <a-descriptions-item label="Keluar Karena">
          <a-select
            size="large"
            class="w-50"
            v-if="isEditable"
            :value="data.keluar_karena ? data.keluar_karena.deskripsi : null"
            @change="(e) => handleChange(e, 'keluar_karena', 'deskripsi')"
          >
            <a-select-option
              v-for="item in keluarKarenaList"
              :key="item.key"
              :value="item.title"
            >
              {{ item.title }}
            </a-select-option>
          </a-select>
          <template v-else>
            {{ data.keluar_karena ? data.keluar_karena.deskripsi : "-" }}
          </template>
        </a-descriptions-item>
        <a-descriptions-item label="Tanggal Keluar">
          <a-date-picker
            v-if="isEditable"
            :value="
              data.tanggal_keluar
                ? moment(data.tanggal_keluar, 'DD MMMM YYYY').locale('id')
                : null
            "
            format="DD MMMM YYYY"
            @change="(e, string) => handleChange(string, 'tanggal_keluar')"
            class="w-50"
            size="large"
          />
          <template v-else>
            {{
              data.tanggal_keluar
                ? parseMoment(data.tanggal_keluar, "DD MMMM YYYY")
                : "-"
            }}
          </template>
        </a-descriptions-item>
        <a-descriptions-item label="Alasan Keluar">
          <a-input
            v-if="isEditable"
            :value="data.alasan_keluar"
            @change="(e) => handleChange(e.target.value, 'alasan_keluar')"
            class="w-100"
            size="large"
          />
          <template v-else>
            {{ data.alasan_keluar ? data.alasan_keluar : "-" }}
          </template>
        </a-descriptions-item>
      </a-descriptions>
    </template>
  </div>
</template>

<script>
import moment from 'moment'
import getDuration from '@/helpers/duration'
import { religions, disabledList, worksList, educationsList, monthlyRevenueList, extracurricularList, jenisPendaftaranList, statusSekolahList, jenjangSekolahList, keluarKarenaList, livedWithList, transportationModeList } from '@/helpers/listsPpdbSelect'
import config from '@/config.js'
const HeaderMenu = () => import('@/components/app/Registration/Header')
const TablePrestasi = () => import('@/components/app/Registration/FormRincianPesertaDidik/TablePrestasi')
const TableBeasiswa = () => import('@/components/app/Registration/FormRincianPesertaDidik/TableBeasiswa')
const columns = [
  {
    title: 'Hubungan',
    dataIndex: 'tipe',
    align: 'center',
    width: 150,
    scopedSlots: {
      customRender: 'hubungan',
    },
    fixed: 'left',
  },
  {
    title: 'Nama',
    dataIndex: 'nama',
    align: 'center',
    width: 300,
    scopedSlots: {
      customRender: 'nullStr',
    },
  },
  {
    title: 'NIK',
    dataIndex: 'nik',
    align: 'center',
    width: 200,
    scopedSlots: {
      customRender: 'nullStr',
    },
  },
  {
    title: 'Tahun Lahir',
    dataIndex: 'tahun_lahir',
    align: 'center',
    width: 100,
    scopedSlots: {
      customRender: 'nullStr',
    },
  },
  {
    title: 'Pendidikan',
    dataIndex: 'pendidikan_terakhir',
    align: 'center',
    width: 200,
    scopedSlots: {
      customRender: 'pendidikan',
    },
  },
  {
    title: 'Penghasilan Bulanan',
    dataIndex: 'penghasilan_bulanan',
    align: 'center',
    width: 250,
    scopedSlots: {
      customRender: 'penghasilan',
    },
  },
  {
    title: 'Berkebutuhan Khusus',
    dataIndex: 'berkebutuhan_khusus',
    align: 'center',
    width: 300,
    scopedSlots: {
      customRender: 'berkebutuhan_khusus',
    },
  },
]

export default {
  components: { HeaderMenu, TablePrestasi, TableBeasiswa },
  props: {
    data: {
      type: Object,
      required: true,
    },
    isEditable: {
      type: Boolean,
      default: () => false,
    },
  },
  data() {
    return {
      current: ['personalData'],
      dataSource: [],
      moment,
      columns,
      religions,
      monthlyRevenueList,
      disabledList,
      educationsList,
      worksList,
      extracurricularList,
      getDuration,
      jenisPendaftaranList,
      statusSekolahList,
      jenjangSekolahList,
      keluarKarenaList,
      livedWithList,
      transportationModeList,
      config,
    }
  },
  methods: {
    handleChange(value, column, innerColumn = '') {
      if (column === 'email' || column === 'email_perwakilan_orangtua') {
        this.dataSource =
          !value || value.indexOf('@') >= 0
            ? []
            : [`${value}@gmail.com`, `${value}@gmail.co.id`, `${value}@yahoo.com`, `${value}@yahoo.co.id`]
      }
      const payload = { value, column, innerColumn }
      this.$emit('handle-change', payload)
    },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
      )
    },
    parseMoment(dateString, format) {
      return moment(dateString, format).locale('id').format(format)
    },
    setValueTable(value, anchor) {
      const dataTitle = this[anchor].find(data => data.name === value)
      // console.log(value, dataTitle)
      if (dataTitle) return dataTitle.title
      return value
    },
    showHubungan(value) {
      if (value === 'ayah') {
        return 'Ayah Kandung'
      } else if (value === 'ibu') {
        return 'Ibu Kandung'
      } else if (value === 'wali') {
        return 'Wali'
      }
    },
  },
  computed: {
    listBerkebutuhanKusus() {
      const mappedData = this.data.berkebutuhan_khusus.map(el => el.deskripsi)
      return mappedData.join(', ')
    },
    listEkstrakurikuler() {
      const mappedData = this.data.jenis_ekstrakurikuler.map(el => el.nama)
      return mappedData.join(', ')
    },
    dataTable() {
      return this.data.orangtuas.map((el) => {
        return {
          ...el,
          key: el.id,
        }
      })
    },
    listPrestasi() {
      return this.data.prestasis.map((el) => {
        return {
          ...el,
          key: el.id,
        }
      })
    },
    listBeasiswa() {
      return this.data.beasiswas.map((el) => {
        return {
          ...el,
          key: el.id,
        }
      })
    },
    files() {
      const data = {}
      if (!this.data.dataLampiranPpdb) return data
      for (let i = 0; i < this.data.dataLampiranPpdb.length; i++) {
        const file = this.data.dataLampiranPpdb[i]
        data[file.deskripsi_lampiran] = file
      }

      return data
    },
  },
}
</script>

<style>
</style>
